<script setup lang="ts">
import type { RouteLocation, LocationQuery, RouteLocationRaw } from 'vue-router';

export interface CiPaginationProps {
  currentPage: number;
  maxVisibleButtons?: number;
  offset: number;
  totalPages: number;
}
const props = withDefaults(defineProps<CiPaginationProps>(), {
  maxVisibleButtons: 4,
});

/********************
 * COMPOSITIONS      *
 ********************/
const route = useRoute();

/********************
 * PROPS & EMITS     *
 ********************/
const isLinkLarge = computed(() => {
  return props.currentPage >= 100;
});

const startPage = computed(() => {
  /* when currentPage is first page */
  if (props.currentPage === 1) {
    return 1;
  }

  /* when currentPage is last page */
  if (props.currentPage === props.totalPages) {
    return props.totalPages - Math.min(props.totalPages, props.maxVisibleButtons) + 1;
  }

  // when is between first and last page
  const sp = Math.min(
    props.maxVisibleButtons === 1 ? props.currentPage : props.currentPage - 1,

    props.totalPages - props.maxVisibleButtons + 1,
  );
  return sp === 0 ? props.currentPage - 1 : sp;
});

const pages = computed(() => {
  const range = [];
  const numberOfButtons = Math.min(props.totalPages, props.maxVisibleButtons);
  let count = startPage.value;
  for (let i = 0; i < numberOfButtons; i += 1) {
    range.push({ count });
    count += 1;
  }

  return range;
});

const firstHref = computed(() => {
  const query = { ...route.query, offset: '0' };
  return getHref(route, query);
});

const lastHref = computed(() => {
  const query = { ...route.query, offset: `${props.offset * props.totalPages - props.offset}` };
  return getHref(route, query);
});

const nextHref = computed(() => {
  const query = { ...route.query, offset: `${props.offset * props.currentPage}` };
  return getHref(route, query);
});

const previousHref = computed(() => {
  // calc new offset
  const query = { ...route.query, offset: `${props.offset * (props.currentPage - 1) - props.offset}` };
  return getHref(route, query);
});

/********************
 * FUNCTIONS         *
 ********************/
function getHrefByPage(page: number) {
  const query = { ...route.query, offset: `${props.offset * page - props.offset}` };
  return getHref(route, query);
}

function getHref(route: RouteLocation, query: LocationQuery) {
  // delete offset for 0
  if (query.offset === '0') {
    delete query.offset;
  }
  return { name: route.name, params: route.params, query } as RouteLocationRaw;
}
</script>

<template>
  <div class="row flex items-center justify-center">
    <ul class="m-0 flex list-none p-0">
      <li class="m-0 p-0">
        <nuxt-link
          v-if="props.currentPage !== 1"
          :to="firstHref"
          class="flex size-[1.875rem] items-center justify-center rounded-full border-0 border-primary bg-transparent font-medium text-primary hover:bg-transparent hover:text-white hover:no-underline"
        >
          <img
            alt=""
            src="~/assets/svg/arrow-double.svg"
          >
        </nuxt-link>
      </li>
      <li class="m-0 p-0">
        <nuxt-link
          v-if="props.currentPage !== 1"
          :to="previousHref"
          class="flex size-[1.875rem] select-none items-center justify-center rounded-full border-0 border-primary bg-transparent p-0 font-medium hover:bg-transparent hover:text-white hover:no-underline"
        >
          <img
            alt=""
            src="~/assets/svg/arrow-black.svg"
          >
        </nuxt-link>
      </li>
      <li
        v-for="page in pages"
        :key="page.count"
        class="m-0 px-[0.313rem] py-0"
      >
        <nuxt-link
          :aria-current="currentPage === page.count"
          :class="[
            currentPage === page.count
              ? 'pointer-events-none bg-primary text-white'
              : 'bg-white text-primary hover:bg-primary hover:text-white',
            {
              'rounded-2xl px-2': isLinkLarge,
            },
          ]"
          :to="getHrefByPage(page.count)"
          class="flex size-[1.875rem] items-center justify-center rounded-full border border-primary font-medium hover:no-underline"
        >
          {{ page.count }}
        </nuxt-link>
      </li>
      <li class="m-0 p-0">
        <nuxt-link
          v-if="props.currentPage !== props.totalPages"
          :to="nextHref"
          class="flex size-[1.875rem] select-none items-center justify-center rounded-full border-0 border-primary bg-transparent p-0 font-medium text-primary hover:bg-transparent hover:text-white hover:no-underline"
        >
          <img
            class="-rotate-180"
            alt=""
            src="~/assets/svg/arrow-black.svg"
          >
        </nuxt-link>
      </li>
      <li class="m-0 p-0">
        <nuxt-link
          v-if="props.currentPage !== props.totalPages"
          :to="lastHref"
          class="flex size-[1.875rem] select-none items-center justify-center rounded-full border-0 border-primary bg-transparent p-0 font-medium text-primary hover:bg-transparent hover:text-white hover:no-underline"
        >
          <img
            class="-rotate-180"
            alt=""
            src="~/assets/svg/arrow-double.svg"
          >
        </nuxt-link>
      </li>
    </ul>
  </div>
</template>

<style></style>
